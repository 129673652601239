import { Link } from 'react-router-dom';

import GoTopPage from '../includes/goTopPage';
import './privacy.css';

function Privacy() {
    return (
        <div className='position-relative'>
            <div className='privacy-container container d-flex flex-column'>
                <h3>プライバシーポリシー</h3>
                <h4 className='mt-3'>最終更新日：2024年5月1日</h4>
                <h6 className='my-3'>
                    <span className='en'>Omowill.com</span>はお客様のプライバシーを尊重いたします。
                </h6>
                <h6>私たちがどのようにあなたの情報を収集、使用、開示、転送、および保存するかについての明確な説明については、私たちのユーザーのプライバシーポリシーをお読みください。あなたに関連付けられている可能性のある情報を確認する方法をご覧ください。</h6>

                <div className='privacy-list-content'>
                    <h3 className='fw-bold mt-3'>
                        私たちはどのように情報を使いますか？
                    </h3>
                    <h4 className='mt-4 mb-3'>
                        当社は収集した情報を以下の方法で使用します：
                    </h4>

                    <ul className="privacy-list">
                        <li className='mb-3'>
                            <h6>
                                当社サイトおよびサービスの運営と提供 - 当社は、当社サイトの運営と管理、および当社サービスの提供、運営、監視、維持のために、お客様の情報を使用します。たとえば、当社は調査回答から収集した情報を使用して調査を実施し、当社サイトのコンテンツを作成します。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                必要な機能の提供および改善 - 当社は、お客様が当社サイトおよび当社サービスを利用する際に必要な機能を提供するために、お客様の情報を利用します。また、機能性、品質、ユーザーエクスペリエンスを向上させるため、当社サイトおよびサービスの利用方法を分析します。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                当社サービスに関するお客様との連絡 - 当社は、お客様による当社サービスへのアクセスおよび当社サービスの利用についてお客様と連絡を取るために、お客様の個人情報を使用します。これには、最新情報の送付、寄付の勧誘、および管理上のメッセージ（当社の利用規約および通知の変更の通知、サービスへの参加の招待、またはディスカッションフォーラムへの投稿の依頼など）が含まれます。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                取引上の配慮 - お客様が当サービスを使用して金融取引を行うことを選択した場合、当社はお客様の情報を使用して取引を完了し、関連情報（購入確認書、領収書、請求書など）を送信します。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                連絡およびサポート依頼の処理 - お客様が当社に連絡した場合（寄付や技術的なヘルプなど）、当社はお客様の情報を使用してお客様の依頼に応じ、お客様と連絡を取ります。また、お客様の個人情報は、お客様のコメント、質問、リクエストに回答し、電子メール、プライベートメッセージ、電話でサポートを提供するために使用します。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                当社のマーケティング活動およびサービスの開発および改善 - 当社は、当社のマーケティング活動およびサービスをパーソナライズおよび改善するために、お客様の個人情報（IPアドレスなど）を使用して、当社のサービス、サイトに関する傾向、利用状況、および相互作用を確認および分析し、その他のマーケティング活動を行います。また、当社は、お客様の興味や嗜好に合ったコンテンツおよび/または機能を提供するため、または当社のマーケティング活動および当社サービスでのお客様の体験をカスタマイズするために、お客様の情報を使用します。当社がお客様の情報をどのように追跡し、使用するかについて詳しくは、当社のクッキーに関する通知をご覧ください。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                当社サイトおよび当社サービスのセキュリティの促進 - 当社は、不正取引、サービスへの不正アクセス、およびその他の悪意ある、欺瞞的な、詐欺的な、または違法な活動を調査および軽減するためにお客様の情報を使用します。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                法律、規制、安全およびコンプライアンス目的 - 当社は、適用される法律または規制を遵守し、当社の利用規約の遵守状況を確認するために、お客様の情報を処理します。
                            </h6>
                        </li>

                        <li className='mb-3'>
                            <h6>
                                その他の目的 - 上記以外の目的でお客様の個人情報を使用する必要がある場合は、事前にお客様に通知し、同意を求めます。
                            </h6>
                        </li>
                    </ul>
                </div>

                <div className='privacy-content'>
                    <h3 className='fw-bold'>
                        クッキーおよびその他のトラッキング技術?
                    </h3>
                    <h6>
                        当社および当社のパートナーは、トレンドの分析、当社サイトおよびサービスの管理、サイトおよびサービスにおけるユーザーの動きの追跡、および当社ユーザーに関する人口統計学的情報の収集のために、Cookieまたは類似の技術を使用します。
                    </h6>
                </div>

                <div className='privacy-content'>
                    <h3>
                        お客様の情報の保護
                    </h3>
                    <h6>
                        当社は、合理的かつ適切な管理的、技術的、物理的な保護措置を用いて、お客様に関する情報を紛失、盗難、不正使用、不正アクセス、不正変更、または不正破棄から保護します。当社はまた、当社に代わって業務を行う第三者サービスプロバイダーや、当社がお客様の情報を共有する第三者サービスプロバイダーに対し、業界標準に準拠したセキュリティ対策を維持するよう求めています。
                    </h6><br />
                    <h6>
                        当社はセキュリティ保護措置を講じていますが、すべての状況において絶対的なセキュリティを保証することはできません。当社のセキュリティ慣行についてご質問がある場合は、<Link to="/contact" className='fw-bold link'>「お問い合わせ」</Link>セクションに記載されているとおり、当社までご連絡ください。お客様ご自身のセキュリティのため、機密の個人情報を当社のサービス外に送信しないでください。また、ご自身のアカウント認証情報のセキュリティと管理を維持し、パスワードを誰とも共有しないことが重要です。
                    </h6>
                </div>

                <div className='privacy-content'>
                    <h3>
                        当社サービスを利用するための最低年齢条件
                    </h3>
                    <h6>
                        当社のサイトおよびサービスは、18歳未満の児童を対象としていません。当社のサイトおよびサービスを利用するには、お客様の法域における個人情報の処理に同意できる年齢である必要があります。当社は、18歳未満の方から故意に個人情報を収集することはありません。18歳未満の方は、当社に個人情報を提供しないでください。保護者の方で、お子様が当社に個人情報を提供していることに気づかれた場合は、当社までご連絡ください。当社は、18歳未満の方から本人確認または保護者の同意なしに個人情報を収集したことが判明した場合、そのような情報を削除する措置を講じます。
                    </h6>
                </div>

                <div className='privacy-content'>
                    <h3 className='fw-bold'>
                        プライバシーに関する通知の変更
                    </h3>
                    <h6>
                        当社は、新しいサービスまたは当社の慣行の変更を説明するために、本通知を定期的に見直し、更新します。本通知が最後に改訂された日付は、本通知の上部にある「更新日」を参照することで確認できます。当社のプライバシー慣行およびお客様のプライバシーを保護するための方法について常に情報を得るために、当社とやり取りするたびに本通知を確認することをお勧めします。
                    </h6>
                    <br />

                    <h6>
                        当社が本通知に重要な変更を加える場合、当社は、当社サイトまたはサービス上の目立つ通知、またはお客様が当社に提供した電子メールアドレスを通じてお客様に通知します。本通知で開示されているプライバシー慣行に同意できない場合は、当社サイトの利用を中止することをお勧めします。
                    </h6>
                </div>

                <div className='privacy-content no-border-bottom'>
                    <h3 className='fw-bold'>
                        お問い合わせ
                    </h3>
                    <h6>
                        本通知に関するご質問、またはお客様の個人情報の更新や変更をご希望の場合は、
                        <Link to="/contact" className='fw-bold link'>privacy@privacy.org </Link>
                        までご連絡いただくか、書面にてご連絡ください。
                    </h6>
                </div>
            </div>
            <GoTopPage />
        </div>
    )
};

export default Privacy;