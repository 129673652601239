// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width:991px) {
    .forget-title-respose{
        margin-top: 60px !important;
    }

    .forget-emailaddress-response{
        margin-top: 50px;
    }

    .forget-btn-respose{
        margin-top: 80px !important;
    }

    .forget-register-respose{
        margin-top: 50px !important;
    }
}

.forgot-pwd .card {
    height: 60vh !important;
}`, "",{"version":3,"sources":["webpack://./src/component/forgot/forgot.css"],"names":[],"mappings":"AAAA;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,2BAA2B;IAC/B;;IAEA;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["@media (max-width:991px) {\r\n    .forget-title-respose{\r\n        margin-top: 60px !important;\r\n    }\r\n\r\n    .forget-emailaddress-response{\r\n        margin-top: 50px;\r\n    }\r\n\r\n    .forget-btn-respose{\r\n        margin-top: 80px !important;\r\n    }\r\n\r\n    .forget-register-respose{\r\n        margin-top: 50px !important;\r\n    }\r\n}\r\n\r\n.forgot-pwd .card {\r\n    height: 60vh !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
