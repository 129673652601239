// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    min-height: 100vh;
    padding-top: 100px;
    text-align: center;
}

.contact-container input, .contact-container textarea, .contact-container button {
    margin-top: 50px;
}

.contact-container button {
    margin-inline: auto;
    padding: 10px 100px;
}

.contact-img {
    width: 60%;
}

@media (max-width: 991px) {
    .contact-container {
        width: 100%;
    }
    .contact-img {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/contact/contact.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".contact-container {\r\n    width: 60%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding-left: 5%;\r\n    padding-right: 5%;\r\n    min-height: 100vh;\r\n    padding-top: 100px;\r\n    text-align: center;\r\n}\r\n\r\n.contact-container input, .contact-container textarea, .contact-container button {\r\n    margin-top: 50px;\r\n}\r\n\r\n.contact-container button {\r\n    margin-inline: auto;\r\n    padding: 10px 100px;\r\n}\r\n\r\n.contact-img {\r\n    width: 60%;\r\n}\r\n\r\n@media (max-width: 991px) {\r\n    .contact-container {\r\n        width: 100%;\r\n    }\r\n    .contact-img {\r\n        display: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
