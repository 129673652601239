// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-container {
    display: flex;    
    margin-top: 100px;
    padding: 110px 70px 0;
    position: relative;
}


.faq-area .list-group {
    margin-top: 35px;
}

.faq-area .list-group-item {
    padding: 0px;
}

.faq-title {
    padding: 30px 0px;
    font-size: 18px;
    cursor: pointer;
}

.faq-title  i {
    font-size: 14px;
}

.faq-content {
    margin-top: 20px;
    margin-bottom: 40px;  
    line-height: 40px;  
}

.faq-contact {
    padding: 40px 0px;
}


.faq-contact a {
    margin-top: 40px;
    width: 200px;
    padding: 10px;
}

.faq-lg-txt {
    position: absolute;
    right: 0px;
    padding-right: 50px;
    padding-top: 45px;
}

.faq-content-row {
    padding-right: 150px;
}

.faq-lg-txt p {
    letter-spacing: normal;
    font-weight: 600;
    font-size: 120px;
    line-height: 1;
    writing-mode: vertical-rl;
}`, "",{"version":3,"sources":["webpack://./src/component/question/faq.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;AACtB;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".faq-container {\r\n    display: flex;    \r\n    margin-top: 100px;\r\n    padding: 110px 70px 0;\r\n    position: relative;\r\n}\r\n\r\n\r\n.faq-area .list-group {\r\n    margin-top: 35px;\r\n}\r\n\r\n.faq-area .list-group-item {\r\n    padding: 0px;\r\n}\r\n\r\n.faq-title {\r\n    padding: 30px 0px;\r\n    font-size: 18px;\r\n    cursor: pointer;\r\n}\r\n\r\n.faq-title  i {\r\n    font-size: 14px;\r\n}\r\n\r\n.faq-content {\r\n    margin-top: 20px;\r\n    margin-bottom: 40px;  \r\n    line-height: 40px;  \r\n}\r\n\r\n.faq-contact {\r\n    padding: 40px 0px;\r\n}\r\n\r\n\r\n.faq-contact a {\r\n    margin-top: 40px;\r\n    width: 200px;\r\n    padding: 10px;\r\n}\r\n\r\n.faq-lg-txt {\r\n    position: absolute;\r\n    right: 0px;\r\n    padding-right: 50px;\r\n    padding-top: 45px;\r\n}\r\n\r\n.faq-content-row {\r\n    padding-right: 150px;\r\n}\r\n\r\n.faq-lg-txt p {\r\n    letter-spacing: normal;\r\n    font-weight: 600;\r\n    font-size: 120px;\r\n    line-height: 1;\r\n    writing-mode: vertical-rl;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
