// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-pdf-home {
    margin-top: 100px;
    height: 400px;
    background-color: aquamarine;
}

.pdf-open:hover {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/component/pdfOrVideo/pdfORvideo.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,0BAA0B;AAC9B","sourcesContent":[".video-pdf-home {\r\n    margin-top: 100px;\r\n    height: 400px;\r\n    background-color: aquamarine;\r\n}\r\n\r\n.pdf-open:hover {\r\n    color: rgba(0, 0, 0, 1);\r\n    font-weight: 700;\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
