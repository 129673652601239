// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
    margin-top: 100px;
    padding: 130px 75px 70px;
    text-align: center;
}

.trust-family {
    margin-top: 100px;    
}

.trust-content {
    margin-top: 70px;
}

.trust-content .card {
    min-width: 33.333%;
    background: rgba(255, 255, 255, 0.7);    
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin: 5px;
    border: 0px;
    border-radius: 0px;
}

.trust-content .card-body {
    padding: 40px;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/component/aboutus/aboutus.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,yCAAyC;IACzC,WAAW;IACX,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".about-container {\r\n    margin-top: 100px;\r\n    padding: 130px 75px 70px;\r\n    text-align: center;\r\n}\r\n\r\n.trust-family {\r\n    margin-top: 100px;    \r\n}\r\n\r\n.trust-content {\r\n    margin-top: 70px;\r\n}\r\n\r\n.trust-content .card {\r\n    min-width: 33.333%;\r\n    background: rgba(255, 255, 255, 0.7);    \r\n    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);\r\n    margin: 5px;\r\n    border: 0px;\r\n    border-radius: 0px;\r\n}\r\n\r\n.trust-content .card-body {\r\n    padding: 40px;\r\n    text-align: left;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
