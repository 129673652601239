// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-container {
    display: flex;    
    margin-top: 100px;
    padding: 110px 70px 0;
    position: relative;
}

.privacy-header-title, .privacy-header-sub-title {
    margin-bottom: 30px;
}

.privacy-title-des {
    margin: 0 0 30px;
    padding: 0;
    text-align: justify;
    font-weight: 500;
    font-size: 15px;
    line-height: 2.3em;
}

.privacy-list-content, .privacy-content {
    margin: 30px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--secondary);
}

.privacy-title-des .link {
    color: var(--primary);
}

.no-border-bottom {
    border: 0px;
}`, "",{"version":3,"sources":["webpack://./src/component/Privacy/privacy.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,yCAAyC;AAC7C;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".privacy-container {\r\n    display: flex;    \r\n    margin-top: 100px;\r\n    padding: 110px 70px 0;\r\n    position: relative;\r\n}\r\n\r\n.privacy-header-title, .privacy-header-sub-title {\r\n    margin-bottom: 30px;\r\n}\r\n\r\n.privacy-title-des {\r\n    margin: 0 0 30px;\r\n    padding: 0;\r\n    text-align: justify;\r\n    font-weight: 500;\r\n    font-size: 15px;\r\n    line-height: 2.3em;\r\n}\r\n\r\n.privacy-list-content, .privacy-content {\r\n    margin: 30px 0px;\r\n    padding-bottom: 30px;\r\n    border-bottom: 1px solid var(--secondary);\r\n}\r\n\r\n.privacy-title-des .link {\r\n    color: var(--primary);\r\n}\r\n\r\n.no-border-bottom {\r\n    border: 0px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
