// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    padding: 130px 75px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 9999;
    background-color: white;
}

.footer-container img {
    margin: 0 0 12px;
    width: 220px;
}

.footer-copy-writer {
    text-align: left;
    letter-spacing: normal;
    font-size: 12px;
    font-family: "Barlow Semi Condensed", sans-serif;
    line-height: 1;
}

@media (max-width: 991px) {
    .footer-container {
        padding: 30px 10px 30px 10px;
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .footer-container .footer-content {
        flex-direction: column;
        gap: 5px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,gDAAgD;IAChD,cAAc;AAClB;;AAEA;IACI;QACI,4BAA4B;QAC5B,sBAAsB;QACtB,SAAS;IACb;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;AACJ","sourcesContent":[".footer-container {\r\n    padding: 130px 75px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n    z-index: 9999;\r\n    background-color: white;\r\n}\r\n\r\n.footer-container img {\r\n    margin: 0 0 12px;\r\n    width: 220px;\r\n}\r\n\r\n.footer-copy-writer {\r\n    text-align: left;\r\n    letter-spacing: normal;\r\n    font-size: 12px;\r\n    font-family: \"Barlow Semi Condensed\", sans-serif;\r\n    line-height: 1;\r\n}\r\n\r\n@media (max-width: 991px) {\r\n    .footer-container {\r\n        padding: 30px 10px 30px 10px;\r\n        flex-direction: column;\r\n        gap: 20px;\r\n    }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .footer-container .footer-content {\r\n        flex-direction: column;\r\n        gap: 5px !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
