// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-list-container {
    margin-top: 100px;
    padding: 130px 75px 70px;
}

.info-header {
    margin-bottom: 40px;
}


.info-list-content {
    margin-bottom: 70px;
}

.info-content-header {
    /* margin: 0 0 50px; */
    margin: 0px;
    padding: 0 0 50px;
    border-bottom: 1px solid var(--secondary);
}


.info-body {
    padding: 60px 40px;
    background-color: var(--secondary-opacity);    
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/component/info/info.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,iBAAiB;IACjB,yCAAyC;AAC7C;;;AAGA;IACI,kBAAkB;IAClB,0CAA0C;IAC1C,yCAAyC;AAC7C","sourcesContent":[".info-list-container {\r\n    margin-top: 100px;\r\n    padding: 130px 75px 70px;\r\n}\r\n\r\n.info-header {\r\n    margin-bottom: 40px;\r\n}\r\n\r\n\r\n.info-list-content {\r\n    margin-bottom: 70px;\r\n}\r\n\r\n.info-content-header {\r\n    /* margin: 0 0 50px; */\r\n    margin: 0px;\r\n    padding: 0 0 50px;\r\n    border-bottom: 1px solid var(--secondary);\r\n}\r\n\r\n\r\n.info-body {\r\n    padding: 60px 40px;\r\n    background-color: var(--secondary-opacity);    \r\n    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
